import {
  DashboardOutlined,
  AppstoreOutlined,
  FileTextOutlined,
  PieChartOutlined,
  EnvironmentOutlined,
  AntDesignOutlined,
  SafetyOutlined,
  StopOutlined,
  DotChartOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  BulbOutlined,
  InfoCircleOutlined,
  CompassOutlined,
  LayoutOutlined,
  DesktopOutlined,
  FileDoneOutlined,
  CommentOutlined,
  RobotOutlined,
  PlusCircleOutlined,
  FundOutlined,
  ShoppingCartOutlined,
  BookOutlined,
  FileUnknownOutlined,
  ProfileOutlined,
  BorderOutlined,
  GlobalOutlined,
  DollarOutlined,
  RightOutlined,
  DoubleRightOutlined,
  QuestionOutlined,
  QuestionCircleOutlined,
  PercentageOutlined,
  MoneyCollectOutlined,
  LineChartOutlined,
  SwapOutlined,
  PayCircleOutlined,
  TagsOutlined,
  SettingOutlined,
  PicCenterOutlined,
  FieldBinaryOutlined,
  MobileOutlined,
  OrderedListOutlined,
  FileProtectOutlined,
  AimOutlined,
  TranslationOutlined,
  PullRequestOutlined,
  NotificationOutlined
} from "@ant-design/icons";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";

const contentNavTree = [
  {
    key: "content",
    path: `${APP_PREFIX_PATH}/content`,
    title: "Content",
    icon: BorderOutlined,
    breadcrumb: true,
    roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
    submenu: [
      {
        key: "orders",
        path: `${APP_PREFIX_PATH}/orders/list`,
        title: "Orders",
        icon: OrderedListOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "users",
        path: `${APP_PREFIX_PATH}/users/list`,
        title: "Users",
        icon: FileTextOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "proxyTypes",
        path: `${APP_PREFIX_PATH}/proxyTypes/list`,
        title: "Proxy types",
        icon: FileTextOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "package-proxy",
        path: `${APP_PREFIX_PATH}/package-proxy/list`,
        title: "Proxy package",
        icon: FileTextOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "blockPayment",
        path: `${APP_PREFIX_PATH}/block/payment/history`,
        title: "Blocked payment history",
        icon: PayCircleOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },

      {
        key: "pages",
        path: `${APP_PREFIX_PATH}/pages/list`,
        title: "Pages",
        icon: FileTextOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "metaPage",
        path: `${APP_PREFIX_PATH}/metaPage/list`,
        title: "Meta page",
        icon: FileTextOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "blog",
        path: `${APP_PREFIX_PATH}/blog/list`,
        title: "Blog",
        icon: FileTextOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "faq",
        path: `${APP_PREFIX_PATH}/faq/list`,
        title: "FAQ",
        icon: QuestionOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "faq-group",
        path: `${APP_PREFIX_PATH}/faq-group/list`,
        title: "FAQ groups",
        icon: QuestionCircleOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "promocode",
        path: `${APP_PREFIX_PATH}/promocode/list`,
        title: "Promocodes",
        icon: TagsOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "review",
        path: `${APP_PREFIX_PATH}/review/list`,
        title: "Reviews",
        icon: CommentOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "seoBlock",
        path: `${APP_PREFIX_PATH}/seo-block/list`,
        title: "SEO Block",
        icon: TranslationOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "priceChangeHistory",
        path: `${APP_PREFIX_PATH}/price-change-history/list`,
        title: "Price change history",
        icon: PayCircleOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "notifications",
        path: `${APP_PREFIX_PATH}/notifications/list`,
        title: "Notifications",
        icon: MailOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "tasks",
        path: `${APP_PREFIX_PATH}/tasks/list`,
        title: "Tasks",
        icon: MailOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      }
    ]
  }
];

const settingsNavTree = [
  {
    key: "settings",
    path: `${APP_PREFIX_PATH}/settings`,
    title: "Settings",
    icon: SettingOutlined,
    breadcrumb: true,
    roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
    submenu: [
      {
        key: "redirect",
        path: `${APP_PREFIX_PATH}/redirect`,
        title: "Redirects",
        icon: PullRequestOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "seo-default",
        path: `${APP_PREFIX_PATH}/seo-default`,
        title: "SEO Default",
        icon: AimOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "meta-tags",
        path: `${APP_PREFIX_PATH}/meta-tags`,
        title: "Meta Tags",
        icon: AimOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "country",
        path: `${APP_PREFIX_PATH}/country/list`,
        title: "Countries",
        icon: GlobalOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "crm-settings",
        path: `${APP_PREFIX_PATH}/settings/crm`,
        title: "Синхронизация с CRM",
        icon: SettingOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "currency",
        path: `${APP_PREFIX_PATH}/currency/list`,
        title: "Currencies",
        icon: MoneyCollectOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "currency-rate",
        path: `${APP_PREFIX_PATH}/currency-rate/list`,
        title: "Currency rate",
        icon: LineChartOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "captcha",
        path: `${APP_PREFIX_PATH}/captcha`,
        title: "Captcha",
        icon: SafetyOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "discount",
        path: `${APP_PREFIX_PATH}/discount/list`,
        title: "Discounts & Mark-ups",
        icon: PercentageOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "goal",
        path: `${APP_PREFIX_PATH}/goal/list`,
        title: "Goals",
        icon: RightOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "goal-group",
        path: `${APP_PREFIX_PATH}/goal-group/list`,
        title: "Goal groups",
        icon: DoubleRightOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "proxy-package",
        path: `${APP_PREFIX_PATH}/proxy-package/list`,
        title: "Proxy packages",
        icon: SwapOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "mobile-operator",
        path: `${APP_PREFIX_PATH}/mobile-operator/list`,
        title: "Mobile operators",
        icon: MobileOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "payment-system",
        path: `${APP_PREFIX_PATH}/payment-system/list`,
        title: "Payment systems",
        icon: PayCircleOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "price",
        path: `${APP_PREFIX_PATH}/price/list`,
        title: "Prices",
        icon: DollarOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      // Hidden because getAllUsers has been removed
      // {
      //   key: "proxy-authentication",
      //   path: `${APP_PREFIX_PATH}/proxy-authentication/list`,
      //   title: "Proxy authentication",
      //   icon: LockOutlined,
      //   breadcrumb: false,
      //   roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
      //   submenu: [],
      // },
      {
        key: "rent-period",
        path: `${APP_PREFIX_PATH}/rent-period/list`,
        title: "Rent periods",
        icon: CalendarOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "files",
        path: `${APP_PREFIX_PATH}/files/list`,
        title: "Files",
        icon: FileProtectOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      }
    ]
  }
];

const templatesNavTree = [
  {
    key: "templates",
    path: `${APP_PREFIX_PATH}/templates`,
    title: "Templates",
    icon: PicCenterOutlined,
    breadcrumb: true,
    roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
    submenu: [
      {
        key: "date-format-template",
        path: `${APP_PREFIX_PATH}/date-format-template/list`,
        title: "Date format",
        icon: FieldBinaryOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "email-template",
        path: `${APP_PREFIX_PATH}/email-template/list`,
        title: "Email template",
        icon: MailOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "email-spam",
        path: `${APP_PREFIX_PATH}/email-spam`,
        title: "Email spam",
        icon: MailOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      },
      {
        key: "popup-notifications",
        path: `${APP_PREFIX_PATH}/popup-notifications`,
        title: "Pop-up Notifications",
        icon: NotificationOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: []
      }
    ]
  }
];

const extraNavTree = [
  {
    key: "extra",
    path: `${APP_PREFIX_PATH}/pages`,
    title: "Pages",
    icon: PlusCircleOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "extra-pages",
        path: `${APP_PREFIX_PATH}/pages`,
        title: "Extra pages",
        icon: FileTextOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "extra-pages-profile",
            path: `${APP_PREFIX_PATH}/pages/profile`,
            title: "Profile",
            icon: "",
            breadcrumb: false,
            submenu: []
          },
          {
            key: "extra-pages-list",
            path: `${APP_PREFIX_PATH}/pages/user-list`,
            title: "Pages' list",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "extra-pages-invoice",
            path: `${APP_PREFIX_PATH}/pages/invoice`,
            title: "Invoice",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "extra-pages-pricing",
            path: `${APP_PREFIX_PATH}/pages/pricing`,
            title: "Prices",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "extra-pages-faq",
            path: `${APP_PREFIX_PATH}/pages/faq`,
            title: "FAQ",
            icon: "",
            breadcrumb: false,
            submenu: []
          },
          {
            key: "extra-pages-setting",
            path: `${APP_PREFIX_PATH}/pages/setting`,
            title: "Settings",
            icon: "",
            breadcrumb: true,
            submenu: []
          }
        ]
      },
      {
        key: "extra-auth",
        path: `${AUTH_PREFIX_PATH}`,
        title: "Auth",
        icon: SafetyOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "extra-auth-login-1",
            path: `${AUTH_PREFIX_PATH}/login-1`,
            title: "Auth 1",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "extra-auth-login-2",
            path: `${AUTH_PREFIX_PATH}/login-2`,
            title: "Auth 2",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "extra-auth-register-1",
            path: `${AUTH_PREFIX_PATH}/register-1`,
            title: "Register 1",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "extra-auth-register-2",
            path: `${AUTH_PREFIX_PATH}/register-2`,
            title: "Register 2",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "extra-auth-forgot-password",
            path: `${AUTH_PREFIX_PATH}/forgot-password`,
            title: "Forgot password",
            icon: "",
            breadcrumb: true,
            submenu: []
          }
        ]
      },
      {
        key: "extra-errors",
        path: `${AUTH_PREFIX_PATH}/error-1`,
        title: "Errors",
        icon: StopOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "extra-errors-error-1",
            path: `${AUTH_PREFIX_PATH}/error-1`,
            title: "Errors 1",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "extra-errors-error-2",
            path: `${AUTH_PREFIX_PATH}/error-2`,
            title: "Errors 2",
            icon: "",
            breadcrumb: true,
            submenu: []
          }
        ]
      }
    ]
  }
];

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "Dashboards",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-default",
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        title: "Default",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: "dashboards-analytic",
        path: `${APP_PREFIX_PATH}/dashboards/analytic`,
        title: "Analytic",
        icon: DotChartOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: "dashboards-sales",
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        title: "Sales",
        icon: FundOutlined,
        breadcrumb: false,
        submenu: []
      }
    ]
  }
];

const appsNavTree = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "Apps",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "apps-mail",
        path: `${APP_PREFIX_PATH}/apps/mail/inbox`,
        title: "Mail",
        icon: MailOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: "apps-chat",
        path: `${APP_PREFIX_PATH}/apps/chat`,
        title: "Chat",
        icon: MessageOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: "apps-calendar",
        path: `${APP_PREFIX_PATH}/apps/calendar`,
        title: "Calendar",
        icon: CalendarOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: "apps-project",
        path: `${APP_PREFIX_PATH}/apps/project`,
        title: "Project",
        icon: BulbOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "apps-project-list",
            path: `${APP_PREFIX_PATH}/apps/project/list`,
            title: "List",
            icon: "",
            breadcrumb: false,
            submenu: []
          },
          {
            key: "apps-project-scrumboard",
            path: `${APP_PREFIX_PATH}/apps/project/scrumboard`,
            title: "Scrumboard",
            icon: "",
            breadcrumb: false,
            submenu: []
          }
        ]
      },
      {
        key: "apps-ecommerce",
        path: `${APP_PREFIX_PATH}/apps/ecommerce`,
        title: "E-Commerce",
        icon: ShoppingCartOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "apps-ecommerce-productList",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/product-list`,
            title: "Product list",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "apps-ecommerce-addProduct",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/add-product`,
            title: "Add product",
            icon: "",
            breadcrumb: false,
            submenu: []
          },
          {
            key: "apps-ecommerce-editProduct",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/edit-product/12`,
            title: "Edit product",
            icon: "",
            breadcrumb: false,
            submenu: []
          },
          {
            key: "apps-ecommerce-orders",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/orders`,
            title: "Orders",
            icon: "",
            breadcrumb: false,
            submenu: []
          }
        ]
      }
    ]
  }
];

const componentsNavTree = [
  {
    key: "components",
    path: `${APP_PREFIX_PATH}/components`,
    title: "Components",
    icon: AntDesignOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "components-general",
        path: `${APP_PREFIX_PATH}/components/general`,
        title: "General",
        icon: InfoCircleOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-general-button",
            path: `${APP_PREFIX_PATH}/components/general/button`,
            title: "Button",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-general-icon",
            path: `${APP_PREFIX_PATH}/components/general/icon`,
            title: "Icon",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-general-typography",
            path: `${APP_PREFIX_PATH}/components/general/typography`,
            title: "Typography",
            icon: "",
            breadcrumb: true,
            submenu: []
          }
        ]
      },
      {
        key: "components-layout",
        path: `${APP_PREFIX_PATH}/components/layout`,
        title: "Layout",
        icon: LayoutOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-layout-grid",
            path: `${APP_PREFIX_PATH}/components/layout/grid`,
            title: "Grid",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-layout-layout",
            path: `${APP_PREFIX_PATH}/components/layout/layout`,
            title: "Layout",
            icon: "",
            breadcrumb: true,
            submenu: []
          }
        ]
      },
      {
        key: "components-navigation",
        path: `${APP_PREFIX_PATH}/components/navigation`,
        title: "Navigation",
        icon: CompassOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-navigation-affix",
            path: `${APP_PREFIX_PATH}/components/navigation/affix`,
            title: "Affix",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-navigation-breadcrumb",
            path: `${APP_PREFIX_PATH}/components/navigation/breadcrumb`,
            title: "Breadcrumb",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-navigation-dropdown",
            path: `${APP_PREFIX_PATH}/components/navigation/dropdown`,
            title: "Dropdown",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-navigation-menu",
            path: `${APP_PREFIX_PATH}/components/navigation/menu`,
            title: "Menu",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-navigation-pagination",
            path: `${APP_PREFIX_PATH}/components/navigation/pagination`,
            title: "Pagination",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-navigation-page-header",
            path: `${APP_PREFIX_PATH}/components/navigation/page-header`,
            title: "PageHeader",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-navigation-steps",
            path: `${APP_PREFIX_PATH}/components/navigation/steps`,
            title: "Steps",
            icon: "",
            breadcrumb: true,
            submenu: []
          }
        ]
      },
      {
        key: "components-data-entry",
        path: `${APP_PREFIX_PATH}/components/data-entry`,
        title: "Data Entry",
        icon: FileDoneOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-data-entry-auto-complete",
            path: `${APP_PREFIX_PATH}/components/data-entry/auto-complete`,
            title: "Autocomplete",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-entry-checkbox",
            path: `${APP_PREFIX_PATH}/components/data-entry/checkbox`,
            title: "Checkbox",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-entry-cascader",
            path: `${APP_PREFIX_PATH}/components/data-entry/cascader`,
            title: "Cascader",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-entry-date-picker",
            path: `${APP_PREFIX_PATH}/components/data-entry/date-picker`,
            title: "DatePicker",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-entry-form",
            path: `${APP_PREFIX_PATH}/components/data-entry/form`,
            title: "Form",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-entry-input-number",
            path: `${APP_PREFIX_PATH}/components/data-entry/input-number`,
            title: "InputNumber",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-entry-input",
            path: `${APP_PREFIX_PATH}/components/data-entry/input`,
            title: "Input",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-entry-mentions",
            path: `${APP_PREFIX_PATH}/components/data-entry/mentions`,
            title: "Mentions",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-entry-rate",
            path: `${APP_PREFIX_PATH}/components/data-entry/rate`,
            title: "Rate",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-entry-radio",
            path: `${APP_PREFIX_PATH}/components/data-entry/radio`,
            title: "Radio",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-entry-switch",
            path: `${APP_PREFIX_PATH}/components/data-entry/switch`,
            title: "Switch",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-entry-slider",
            path: `${APP_PREFIX_PATH}/components/data-entry/slider`,
            title: "Slider",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-entry-select",
            path: `${APP_PREFIX_PATH}/components/data-entry/select`,
            title: "Select",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-entry-tree-select",
            path: `${APP_PREFIX_PATH}/components/data-entry/tree-select`,
            title: "TreeSelect",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-entry-transfer",
            path: `${APP_PREFIX_PATH}/components/data-entry/transfer`,
            title: "Transfer",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-entry-time-picker",
            path: `${APP_PREFIX_PATH}/components/data-entry/time-picker`,
            title: "TimePicker",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-entry-upload",
            path: `${APP_PREFIX_PATH}/components/data-entry/upload`,
            title: "Upload",
            icon: "",
            breadcrumb: true,
            submenu: []
          }
        ]
      },
      {
        key: "components-data-display",
        path: `${APP_PREFIX_PATH}/components/data-display`,
        title: "Data Display",
        icon: DesktopOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-data-display-avatar",
            path: `${APP_PREFIX_PATH}/components/data-display/avatar`,
            title: "Avatar",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-display-badge",
            path: `${APP_PREFIX_PATH}/components/data-display/badge`,
            title: "Badge",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-display-comment",
            path: `${APP_PREFIX_PATH}/components/data-display/comment`,
            title: "Comment",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-display-collapse",
            path: `${APP_PREFIX_PATH}/components/data-display/collapse`,
            title: "Collapse",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-display-carousel",
            path: `${APP_PREFIX_PATH}/components/data-display/carousel`,
            title: "Carousel",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-display-card",
            path: `${APP_PREFIX_PATH}/components/data-display/card`,
            title: "Card",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-display-calendar",
            path: `${APP_PREFIX_PATH}/components/data-display/calendar`,
            title: "Calendar",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-display-descriptions",
            path: `${APP_PREFIX_PATH}/components/data-display/descriptions`,
            title: "Descriptions",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-display-empty",
            path: `${APP_PREFIX_PATH}/components/data-display/empty`,
            title: "Empty",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-display-list",
            path: `${APP_PREFIX_PATH}/components/data-display/list`,
            title: "List",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-display-popover",
            path: `${APP_PREFIX_PATH}/components/data-display/popover`,
            title: "Popover",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-display-statistic",
            path: `${APP_PREFIX_PATH}/components/data-display/statistic`,
            title: "Statistic",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-display-tree",
            path: `${APP_PREFIX_PATH}/components/data-display/tree`,
            title: "Tree",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-display-tooltip",
            path: `${APP_PREFIX_PATH}/components/data-display/tooltip`,
            title: "Tooltip",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-display-timeline",
            path: `${APP_PREFIX_PATH}/components/data-display/timeline`,
            title: "Timeline",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-display-tag",
            path: `${APP_PREFIX_PATH}/components/data-display/tag`,
            title: "Tag",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-display-tabs",
            path: `${APP_PREFIX_PATH}/components/data-display/tabs`,
            title: "Tabs",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-data-display-table",
            path: `${APP_PREFIX_PATH}/components/data-display/table`,
            title: "Table",
            icon: "",
            breadcrumb: true,
            submenu: []
          }
        ]
      },
      {
        key: "components-feedback",
        path: `${APP_PREFIX_PATH}/components/feedback`,
        title: "Feedback",
        icon: CommentOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-feedback-alert",
            path: `${APP_PREFIX_PATH}/components/feedback/alert`,
            title: "Alert",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-feedback-drawer",
            path: `${APP_PREFIX_PATH}/components/feedback/drawer`,
            title: "Drawer",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-feedback-modal",
            path: `${APP_PREFIX_PATH}/components/feedback/modal`,
            title: "Modal",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-feedback-message",
            path: `${APP_PREFIX_PATH}/components/feedback/message`,
            title: "Message",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-feedback-notification",
            path: `${APP_PREFIX_PATH}/components/feedback/notification`,
            title: "Notification",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-feedback-progress",
            path: `${APP_PREFIX_PATH}/components/feedback/progress`,
            title: "Progress",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-feedback-popconfirm",
            path: `${APP_PREFIX_PATH}/components/feedback/popconfirm`,
            title: "Popconfirm",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-feedback-result",
            path: `${APP_PREFIX_PATH}/components/feedback/result`,
            title: "Result",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-feedback-spin",
            path: `${APP_PREFIX_PATH}/components/feedback/spin`,
            title: "Spin",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-feedback-skeleton",
            path: `${APP_PREFIX_PATH}/components/feedback/skeleton`,
            title: "Skeleton",
            icon: "",
            breadcrumb: true,
            submenu: []
          }
        ]
      },
      {
        key: "components-other",
        path: `${APP_PREFIX_PATH}/components/other`,
        title: "Other",
        icon: RobotOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-other-anchor",
            path: `${APP_PREFIX_PATH}/components/other/anchor`,
            title: "Anchor",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-other-backtop",
            path: `${APP_PREFIX_PATH}/components/other/backtop`,
            title: "Backtop",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-other-config-provider",
            path: `${APP_PREFIX_PATH}/components/other/config-provider`,
            title: "ConfigProvider",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-other-divider",
            path: `${APP_PREFIX_PATH}/components/other/divider`,
            title: "Divider",
            icon: "",
            breadcrumb: true,
            submenu: []
          }
        ]
      },
      {
        key: "components-charts",
        path: `${APP_PREFIX_PATH}/components/charts`,
        title: "Charts",
        icon: PieChartOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-charts-apex",
            path: `${APP_PREFIX_PATH}/components/charts/apex-charts`,
            title: "Apex",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-charts-chartjs",
            path: `${APP_PREFIX_PATH}/components/charts/chartjs`,
            title: "Chartjs",
            icon: "",
            breadcrumb: true,
            submenu: []
          }
        ]
      },
      {
        key: "components-maps",
        path: `${APP_PREFIX_PATH}/components/maps`,
        title: "Maps",
        icon: EnvironmentOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-maps-google",
            path: `${APP_PREFIX_PATH}/components/maps/google-map`,
            title: "Google",
            icon: "",
            breadcrumb: true,
            submenu: []
          },
          {
            key: "components-maps-simple",
            path: `${APP_PREFIX_PATH}/components/maps/simple-map`,
            title: "Simple",
            icon: "",
            breadcrumb: true,
            submenu: []
          }
        ]
      }
    ]
  }
];

const docsNavTree = [
  {
    key: "docs",
    path: `${APP_PREFIX_PATH}/docs`,
    title: "Docs",
    icon: BookOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "docs-documentation",
        path: `${APP_PREFIX_PATH}/docs/documentation`,
        title: "Documentation",
        icon: FileUnknownOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: "docs-changelog",
        path: `${APP_PREFIX_PATH}/docs/documentation/changelog`,
        title: "Changelog",
        icon: ProfileOutlined,
        breadcrumb: false,
        submenu: []
      }
    ]
  }
];

const navigationConfig = [...contentNavTree, ...templatesNavTree, ...settingsNavTree];

if (process.env.NODE_ENV === "development") {
  navigationConfig.push(
    ...dashBoardNavTree,
    ...appsNavTree,
    ...componentsNavTree,
    ...extraNavTree,
    ...docsNavTree
  );
}

export default navigationConfig;
