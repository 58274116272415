import { $api } from "services/AxiosInstance";

export class MobileOperatorService {
  static getAllMobileOperators() {
    return $api.get("/api/admin/mobileOperator/all");
  }

  static getMobileOperators(params) {
    return $api.post("/api/admin/mobileOperator/all", params);
  }

  static getMobileOperator(id) {
    return $api.get("/api/admin/mobileOperator", { params: { id } });
  }

  static createMobileOperator(values) {
    return $api.post("/api/admin/mobileOperator", values);
  }

  static updateMobileOperator(values) {
    return $api.put("/api/admin/mobileOperator", values);
  }

  static deleteMobileOperator(id) {
    return $api.delete("/api/admin/mobileOperator", { params: { id } });
  }

  static setOperatorRotations(params) {
    return $api.put("/api/admin/mobileOperator/pricesByRotationMinutes", params);
  }
  static getPricesByRotationMinutes(mobileOperatorId) {
    return $api.get(
      `/api/admin/mobileOperator/pricesByRotationMinutes?mobileOperatorId=${mobileOperatorId}`
    );
  }

  static getAllMobileTags() {
    return $api.get("/api/admin/mobileOperator/crm/tags");
  }
}
